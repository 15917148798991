
.pageheaderComponent {
    padding: 4px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #eee;

    .leftBox {
        width: 15%;
        display: flex;
        align-items: center;
        gap: 2px;

        .dash-icon {
            font-size: 26px;
        }

        .dash-title {
            margin: 0;
            margin-top: 6px;
            font-size: 22px;
            font-weight: 800;
            color: Black;
            font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        }
    }

    .rightBox {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.5rem;

        .item-box {
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                color: black;
            }
        }
    }
}