@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Permanent+Marker&family=Play&display=swap');

$PrimaryColor: #2F4454;
$SecondaryColor: #2E151B;
$TertiaryColor: #99738E;
$ErrorColor : #F64C72;
$OptionalColor : #2F2FA2;
$WhiteColor :#FFFFFF;
$TextBlack: #202020;
$ButtonColor: #007aff;
$BorderColor: #DBE9FA;
$PrimaryFont: 'Play', sans-serif;
$SecondaryFont : 'Permanent Marker',cursive;
$TertiaryFont : 'Josefin Sans',sans-serif;



.page_404 {
    background: $WhiteColor;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        width: 100%;
        .row {
            width: 100%;
            .col-sm-12 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .col-sm-offset-1 {
                    width: 100%;
                    .four_zero_four_bg {
                        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
                        height: 38rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        h1{
                            text-align: center;
                            font-size: 10rem;
                            font-weight: 700;
                            margin: 0;
                            color: $PrimaryColor;
                            font-family: $TertiaryFont;
                        }
                    }

                    .contant_box_404 {
                        margin-top: -9.375rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 1rem;
                        .h2{
                            margin: 0;
                            font-size: 32px;
                            font-family: $TertiaryFont;
                            color: $ErrorColor;
                            font-weight: 700;
                        }
                        p{
                            margin: 0;
                            font-size: 26px;
                            font-family: $TertiaryFont;
                            color: $OptionalColor;
                        }
                        .link_404 {
                            color: $WhiteColor !important;
                            background: $ButtonColor;
                            width: 180px;
                            height: 50px;
                            font-weight: 600;
                            font-size: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            border-radius: 4px;
                            transition: 0.5s;
                        }
                        .link_404:hover{
                            transform: scale(1.1);
                            transition: 0.5s;
                        }
                    }
                }
            }
        }
    }

}