.ordersComponents {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .ordersContainer {
        margin-top: 80px;
        width: 95%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .ordertableContainer {
            width: 100%;
            height: 93%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .item-row {
                cursor: pointer;
            }
        }

    }
}