

.cardBox{
    width: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    .cardItem{
        width: 100%;
        padding: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        .icons{
            font-size: 4rem;
        }
        .textBox{
            width: inherit;
            .title{
                margin: 0;
                font-weight: 700;
                font-size: 24px;
            }
            .tags{
                margin: 0;
                width: 100%;
                font-weight: 600;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .miniIcon{
                    font-size: 22px;
                    color: #747474;
                }
            }
        }
    }
}

@media screen and (max-width : 990px) {
    .cardBox{
        gap: 2rem;
        .cardItem{
            width: 100%;
            padding: 1.5rem;
        }
    }
}