.prof-box{
    display: flex;
    flex-direction: column;
    width: 47%;
    gap: 1rem;
}
.profileContainer {
    padding: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    .elementTitle {
        margin: 0;
        font-weight: 600;
    }

    .userIcon {
        margin-top: 1rem;
        width: 7rem;
        height: 7rem;
    }

    .title {
        margin: 0;
        margin-top: 1rem;
        font-weight: 600;
    }

    .para {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .divider {
        margin: 8px 0px;
        border-bottom: 2px solid #eee;
        width: 50%;
    }

    .tatleTag {
        margin: 6px 0px;
        font-size: 20px;
        font-weight: 600;
    }

    .btn {
        margin-top: 1.5rem;
        height: 3rem;
        font-weight: 600;
        color: white;
        width: 60%;
        border: none;
        background-color: rgb(34, 105, 186);
    }
}

@media screen and (max-width : 990px) {

    .profileContainer {
        padding: 2rem;

        .userIcon {
            width: 6rem;
            height: 6rem;
        }

        .divider {
            width: 100%;
        }

        .para {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }

        .tatleTag {
            font-size: 16px;
        }

        .btn {
            width: 100%;
        }
    }
}