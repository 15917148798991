.orderDetailsComponents {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .orderDetailsContainer {
        margin-top: 80px;
        width: 95%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .orderContainer {
            width: 100%;
            height: 93%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;


            .tableContainer {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .imgBox {
                    padding: 1rem;
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    border-radius: 4px;
                    .productimg {
                        width: 100%;
                        max-height: 100%;
                        border-radius: 4px;
                    }
                }
            }
        }

    }
}