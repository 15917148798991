@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Montserrat&family=Permanent+Marker&family=Play&display=swap');
$PrimaryColor: black;
$SecondaryColor: #2E151B;
$TertiaryColor: #99738E;
$ErrorColor : #F64C72;
$OptionalColor : #2F2FA2;
$WhiteColor :#FFFFFF;
$TextBlack: #202020;
$ButtonColor: #007aff;
$BorderColor: #DBE9FA;
$PrimaryFont: 'Play', sans-serif;
$SecondaryFont : 'Permanent Marker',
cursive;
$TertiaryFont : 'Josefin Sans',
sans-serif;
$TextFont : 'Montserrat',
sans-serif;



.navbar {
    top: 0;
    width: 100%;
    height: 80px;
    position: fixed !important;
    background-color: $PrimaryColor;
    z-index: 1000000;

    .box-container {
        width: 90%;
        .brand {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            .logo {
                height: 80px;
            }
        }



        .items-box {
            align-items: center;
            gap: 1.5rem;
            .href-item {
                text-decoration: none;
                font-size: 18px;
                font-weight: 600;
                color: $WhiteColor;
                font-family: $TextFont;
                transition: 0.5s;
            }

            .href-item:hover {
                transform: scale(1.1);
                transition: 0.5s;
            }
        }
        .add-btn{
            border: none;
            font-size: 20px;
            font-weight: 600;
            color: white;
            background: none;
        }
    }
}



@media screen and (max-width : 768px) {
    .navbar {
        .box-container {
            width: 95%;
            .brand {
                .logo {
                    height: 60px;
                }
            }
        }
    }

    .menu-btn {
        background-color: $BorderColor !important;
    }

    .mobile-navigation {
        background-color: $PrimaryColor !important;

        .mobile-nav {

            .nav-head-box {
                display: flex;
                align-items: center;

                .logo {
                    height: 30px;
                }
            }

        }

        .items-box {
            gap: 1rem;

            .href-item {
                text-decoration: none;
                font-family: $TextFont;
                border: 1px solid $BorderColor !important;
                font-size: 18px !important;
                font-weight: 600 !important;
                color: $BorderColor !important;
                padding: 6px 0;
                margin: 8px 0;
                text-align: center;
                border-radius: 4px;
            }
        }

        .add-btn{
            margin-top: 0.5rem;
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 4px;
            font-size: 20px;
            font-weight: 600;
            color: black;
        }

        .href-item:hover {
            background-color: $ButtonColor;
            color: $WhiteColor !important;
        }

    }
}