.dashboardComponents {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .dashboardContainer {
        margin-top: 80px;
        width: 95%;
        height: 90vh;
        display: flex;
        flex-direction: column;

        .btnContainer{
            width: 100%;
            // border: 1px solid #eee;
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            border-bottom: 1px  solid #eee;
            .btnItem{
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                background-color: #eee;
                padding: 6px 16px;
                border-radius: 4px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                color: rgb(11, 109, 208);
                cursor: pointer;
                .icon{
                    font-size: 24px;
                }
            }
        }
        
        .dashboardtableContainer{
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .contentBox{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        
    }
}