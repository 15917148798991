$primaryColor : #000000;

.login-component {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: $primaryColor;
    position: relative;

    .login-image-container {
        width: 100%;
        height: 100%;

        .login-img {
            width: 100%;
        }
    }

    .login-right-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);

        .form-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 500px;

            .brand-box {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #eee;

                .logo {
                    width: 100px;
                }

                .text-box {
                    width: 70%;
                    padding: 0 0.9rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    border-left: 1px solid #eee;

                    .title {
                        margin: 0;
                        font-size: 28px;
                        color: white;
                        font-weight: 600;
                    }

                    .para {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400;
                        color: white;
                    }
                }





            }

            .inputbox {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .input {
                    width: 75%;
                    height: 40px;
                    background: none !important;
                    border: none;
                    font-size: 20px;
                    color: white;
                    border-bottom: 1px solid #eee;
                }

                .label {
                    margin: 0;
                    width: 25%;
                    font-size: 20px;
                    color: white;
                }

                .input:focus {
                    outline: none;
                }
            }

            .bottom-box {
                width: 100%;
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    margin-left: 25%;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 20px;
                    color: white;

                    input {
                        color: white;
                        width: 20px;
                        height: 20px;
                    }
                }

                p {
                    margin: 0;
                    font-size: 20px;
                    color: white;
                    cursor: pointer;
                }
            }

            .buttom-box {
                width: 100%;
                margin-top: 1rem;

                .sunbit-btn {
                    margin-left: 25%;
                    width: 200px;
                    height: 50px;
                    font-size: 20px;
                    font-weight: 600;
                    border-radius: 4px;
                    border: none;
                }
            }

        }
    }
}

@media screen and (max-width : 1440px) {
    .login-component{
        .login-image-container{
            .login-img{
                height: 100%;
                width: auto;
            }
        }
    }
}

@media screen and (max-width : 1200px) {
    .login-component{
        .login-right-container{
            width: 600px;
        }
    }
}

@media screen and (max-width : 600px) {
    .login-component{
        .login-right-container{
            width: 100%;
            .form-box{
                width: 90%;
                .brand-box {
                    .logo {
                        width: 80px;
                    }
                    .text-box {
                        .title {
                            font-size: 22px;
                        }
                        .para {
                            font-size: 14px;
                        }
                    }

                }

                .inputbox {
                    .input {
                        font-size: 16px;
                    }
    
                    .label {
                        font-size: 16px;
                    }
                }


                .bottom-box {
                    margin-top: 0;
                    label {
                        font-size: 16px;
                        input {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }
    
                .buttom-box {
                    margin-top: 0rem;
    
                    .sunbit-btn {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width : 425px) {
    .login-component{
        .login-right-container{
            .form-box{
                padding: 0;
                .brand-box {
                    flex-direction: column;
                    padding: 0.5rem;
                    .logo {
                        width: 70px;
                    }
                    .text-box {
                        text-align: center;
                        width: 100%;
                        align-items: center;
                        border: none;
                    }

                }

                .inputbox {
                    flex-direction: column;
                    gap: 0.5rem;
                    height: auto;
                    .input {
                        width: 100%;
                        font-size: 16px;
                    }
    
                    .label {
                        width: 100%;
                        font-size: 16px;
                    }
                }


                .bottom-box {
                    margin-top: 0;
                    label {
                        margin: 0;
                        font-size: 16px;
                        input {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    p {
                        font-size: 16px;
                    }
                }
    
                .buttom-box {
                    margin-top: 0rem;
    
                    .sunbit-btn {
                        margin: 0;
                        width: 100%;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}